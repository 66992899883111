var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "create-account" },
    [
      _c("h1", [_vm._v("Free Account Signup")]),
      _c("validation-observer", {
        staticClass: "fields form-group",
        attrs: { tag: "form" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "col-6", attrs: { label: "First Name" } },
                      [
                        _c(
                          "validation-provider",
                          {
                            attrs: {
                              rules: "required",
                              immediate: "",
                              name: "First Name",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "first",
                                placeholder: "First Name",
                                "aria-describedby":
                                  "input-live-help input-live-feedback",
                                autocomplete: "first name",
                                trim: "",
                              },
                              model: {
                                value: _vm.firstName,
                                callback: function ($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      { staticClass: "col-6", attrs: { label: "Last Name" } },
                      [
                        _c(
                          "validation-provider",
                          {
                            attrs: {
                              rules: "required",
                              immediate: "",
                              name: "Last Name",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "last",
                                placeholder: "Last Name",
                                "aria-describedby":
                                  "input-live-help input-live-feedback",
                                autocomplete: "last name",
                                trim: "",
                              },
                              model: {
                                value: _vm.lastName,
                                callback: function ($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Email:" } },
                  [
                    _c("validation-provider", {
                      attrs: {
                        rules: "required|email",
                        immediate: "",
                        name: "Email",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "email",
                                    placeholder: "Email Address",
                                    "aria-describedby":
                                      "input-live-help input-live-feedback",
                                    autocomplete: "username email",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function ($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email",
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Password:" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "password",
                        type: _vm.passwordInputType,
                        placeholder: "Password",
                        autocomplete: "password",
                        "aria-describedby":
                          "input-live-help input-live-feedback",
                        trim: "",
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Confirm Password:" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "confirmPassword",
                        type: _vm.passwordInputType,
                        placeholder: "Password",
                        autocomplete: "password",
                        "aria-describedby":
                          "input-live-help input-live-feedback",
                        trim: "",
                      },
                      model: {
                        value: _vm.confirmPassword,
                        callback: function ($$v) {
                          _vm.confirmPassword = $$v
                        },
                        expression: "confirmPassword",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "mb-2 mr-sm-2 mb-sm-3",
                    attrs: {
                      id: "showPasswordCheckbox",
                      value: true,
                      "unchecked-value": false,
                    },
                    model: {
                      value: _vm.showPassword,
                      callback: function ($$v) {
                        _vm.showPassword = $$v
                      },
                      expression: "showPassword",
                    },
                  },
                  [_vm._v("\n      Show Password\n    ")]
                ),
                _vm.password.length > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.unsatisfiedRules, function (rule) {
                        return _c("li", { key: rule.id }, [
                          _vm._v(
                            "\n        " + _vm._s(rule.description) + "\n      "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      id: "submit",
                      disabled: !_vm.validPassword || invalid,
                    },
                    on: { click: _vm.createAccount },
                  },
                  [_vm._v("\n      Submit\n    ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }