<template>
  <b-container class="create-account">
    <h1>Free Account Signup</h1>
    <validation-observer v-slot="{ invalid }" tag="form" class="fields form-group">
      <div class="row">
        <b-form-group class="col-6" label="First Name">
          <validation-provider rules="required" immediate name="First Name">
            <b-form-input
              id="first"
              v-model="firstName"
              placeholder="First Name"
              aria-describedby="input-live-help input-live-feedback"
              autocomplete="first name"
              trim
            />
          </validation-provider>
        </b-form-group>

        <b-form-group class="col-6" label="Last Name">
          <validation-provider rules="required" immediate name="Last Name">
            <b-form-input
              id="last"
              v-model="lastName"
              placeholder="Last Name"
              aria-describedby="input-live-help input-live-feedback"
              autocomplete="last name"
              trim
            />
          </validation-provider>
        </b-form-group>
      </div>

      <b-form-group label="Email:">
        <validation-provider v-slot="{ errors }" rules="required|email" immediate name="Email">
          <b-form-input
            id="email"
            v-model="email"
            placeholder="Email Address"
            aria-describedby="input-live-help input-live-feedback"
            autocomplete="username email"
            trim
          />
          <span>{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Password:">
        <b-form-input
          id="password"
          v-model="password"
          :type="passwordInputType"
          placeholder="Password"
          autocomplete="password"
          aria-describedby="input-live-help input-live-feedback"
          trim
        />
      </b-form-group>

      <b-form-group label="Confirm Password:">
        <b-form-input
          id="confirmPassword"
          v-model="confirmPassword"
          :type="passwordInputType"
          placeholder="Password"
          autocomplete="password"
          aria-describedby="input-live-help input-live-feedback"
          trim
        />
      </b-form-group>

      <b-form-checkbox
        id="showPasswordCheckbox"
        v-model="showPassword"
        :value="true"
        :unchecked-value="false"
        class="mb-2 mr-sm-2 mb-sm-3"
      >
        Show Password
      </b-form-checkbox>

      <ul v-if="password.length > 0">
        <li v-for="rule in unsatisfiedRules" :key="rule.id">
          {{ rule.description }}
        </li>
      </ul>

      <b-button id="submit" class="btn btn-primary" :disabled="!validPassword || invalid" @click="createAccount">
        Submit
      </b-button>
    </validation-observer>
  </b-container>
</template>

<script>
  import { RESET } from '@/store/actions'
  import store from '@/store'
  import http from '@/http'
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { email } from 'vee-validate/dist/rules'

  extend('email', email)

  export default {
    name: 'FreeAccountSignup',
    components: { ValidationObserver, ValidationProvider },
    data() {
      return {
        showPassword: this.showPassword || false,
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
      }
    },

    computed: {
      hasToken () {
        return (
          this.$store.state.session.token !== undefined && this.$store.state.session.token != null
        )
      },

      unsatisfiedRules () {
        return this.rules.filter(rule => !rule.state)
      },

      rules: {
        get() {
          return [
            {
              id: 0,
              description: 'Password must have 8 or more characters',
              state: this.password.length >= 8,
            },
            {
              id: 1,
              description: 'Password must  contain a number',
              state: this.password.search(/[0-9]/) > -1,
            },
            {
              id: 2,
              description: 'Password must  contain a uppercase & lowercase letter',
              state: this.password.search(/[a-z]/) > -1 && this.password.search(/[A-Z]/) > -1,
            },
            {
              id: 3,
              description: 'Password must contain one symbol (`!@#$%^&*)',
              state: this.password.search(/[^ \w]/) > -1,
            },
            {
              id: 4,
              description: 'Passwords must match',
              state: this.password === this.confirmPassword && this.confirmPassword.length > 0,
            },
          ]
        },
      },

      validPassword () {
        return this.unsatisfiedRules.length === 0
      },

      passwordInputType () {
        return this.showPassword
          ? 'text'
          : 'password'
      },
    },

    async mounted () {
      if (this.hasToken) {
        const params = { ...this.$route.params }
        await store.dispatch(RESET, {}, { root: true })
        this.$router.push({ name: 'freeAccountSignup', params })
      }
    },
    methods: {
      async createAccount () {
        try {
          const response = await http.post(`client/signup`, {
            email_address: this.email,
            password: this.password,
            first_name: this.firstName,
            last_name: this.lastName,
            website_id: this.$route.params['websiteId'],
            email_hash: this.$route.params['emailHash'],
          })

          if (response.data && response.data.success) {

            alert("Account created successfully. You will now be redirected to the login page.")

            if (location.hostname !== 'localhost') {
              const subdomain = response.data.result.subdomain
              window.location.href = `https://${subdomain}.onlineaccount.net/#/login`
            } else {
              this.$router.push({ name: 'login' })
            }

          } else if (response.data && response.data.error) {
            this.failToast()
          }
        } catch (error) {
          console.log(error)
          this.failToast()
        }
      },

      failToast() {
        this.$bvToast.toast('There was a problem creating this account. Please verify that this email has been invited.', {
          title: 'Error',
          autoHideDelay: 4000,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .create-account {
    max-width: 460px;
  }

  h1 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  button:disabled {
    background-color: darkgray;
    border-color: darkgray;
  }

  ol,
  ul {
    padding-left: 20px;
  }
</style>
